
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import {Box, Center, VStack} from '@chakra-ui/react'

import {GetStaticProps} from 'next'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import {useRouter} from 'next/router'
import {useMemo} from 'react'
import SearchBar from '../components/Common/SearchBar/SearchBar'
import SoftwareSection from '../components/Common/SoftwareSection/SoftwareSection'
import {SchemaSoftwareSourceCode} from '../components/Form/schema'
import Attribution from '../components/Home/Attribution'
import FairLevelHome from '../components/Home/FairLevelHome'
import Hero from '../components/Home/Hero'
import HeadModule from '../components/Modules/HeaderModules'
import MainLayout from '../components/layouts/Mainlayout'
import {ROUTES_SEARCH} from '../constants/routes'
import {getPopularSoftware} from '../fetchers/software.server'
import {useIsDesktop} from '../hooks/useIsDesktop'

interface Props {
  popularSoftwaresData: string
}

const Home = ({popularSoftwaresData}: Props) => {
  const popularSoftwares = useMemo(
    () => JSON.parse(popularSoftwaresData) as SchemaSoftwareSourceCode[],
    [popularSoftwaresData]
  )
  const router = useRouter()
  const {t} = useTranslation()
  const isDesktop = useIsDesktop()

  return (
    <MainLayout>
      <HeadModule title='meta_title' description='meta_description' />
      <VStack w='full' spacing={0}>
        <Hero />
        <Center mt='+5%' w='full'>
          <SearchBar
            placeholder={t('search:bar_placeholder_home')}
            isHome
            onQuery={item => router.push(ROUTES_SEARCH([{name: 'query', value: item}]))}
          />
        </Center>
        {isDesktop && (
          <Box w='940px' top={700} h={303} zIndex={-1} pb='200px' position='absolute'>
            <Image
              src='/hero/home_Imaging.svg'
              layout='fill'
              objectFit='contain'
              alt='imaging-background'
            />
          </Box>
        )}
        <FairLevelHome />
        <Attribution />
        {popularSoftwares && (
          <SoftwareSection
            softwareList={popularSoftwares}
            title={t('common:home_title_software')}
          />
        )}
      </VStack>
    </MainLayout>
  )
}

// noinspection JSUnusedGlobalSymbols
 const _getStaticProps: GetStaticProps = async () => {
  //TODO this does not fetch popular software but just 6 software at random
  const popularSoftwaresData = JSON.stringify(await getPopularSoftware())

  return {
    props: {
      popularSoftwaresData,
    },
    //Remake page on fetch every 15 minutes
    revalidate: 900,
  }
}

// noinspection JSUnusedGlobalSymbols
export default Home


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  