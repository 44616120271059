import {Button, Flex, Heading, Text, useTheme} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import {ROUTES_FAQ_FAIR} from '../../constants/routes'
import Image from 'next/image'
import ArrowRight from '../Icons/solid/ArrowRight.svg'

const FairLevelHome = () => {
  const theme = useTheme()
  const {t} = useTranslation()

  return (
    <Flex w='full' justifyContent='center' py={{base: '100px', md: '180px'}}>
      <Flex
        w='full'
        direction='column'
        alignItems='center'
        backgroundColor={theme.colors.brand.superLightGreen}
        borderRadius='big'
        py={{base: 10, md: 20}}
        gap={10}>
        <Flex w='full' direction='column' alignItems='center' gap={{base: 2, md: 4}}>
          <Heading fontSize={{base: 28, md: 48}} textAlign='center'>
            {t('common:fair_level_title')}
          </Heading>
          <Text variant='large' align='center'>
            {t('common:fair_level_subtitle')}
          </Text>
        </Flex>
        <Flex w='full' direction='column' alignItems='center' gap={{base: 2, md: 4}}>
          <Image src='/logos/FAIR_level_1_to_5.svg' height={178} width={178} alt='fair level' />
          <Text fontSize={16} align='center'>
            {t('common:fair_level_description')}
          </Text>
        </Flex>
        <Link href={ROUTES_FAQ_FAIR} passHref>
          <Button
            variant='searchBig'
            rightIcon={<ArrowRight color={theme.colors.brand.white} height='12px' width='12px' />}>
            {t('common:fair_level_button')}
          </Button>
        </Link>
      </Flex>
    </Flex>
  )
}
export default FairLevelHome
